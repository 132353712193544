<template>
  <v-card v-if="loaded">
    <PreviewModal v-if="showPreview" :informationalBlock="informationalBlock" :show.sync="showPreview"></PreviewModal>
    <v-card-title class="card-title elevation-3 d-flex justify-space-between">
      <span> Настройка витрины </span>
    </v-card-title>
    <v-divider/>
    <v-card-text class="mt-5">
      <v-container fluid class="showcase">
        <div class="d-flex flex-column showcase__wrapper">
          <v-card>
            <v-card-text class="d-flex flex-column">
          <span class="showcase__name">
            Наименование витрины
          </span>
              <v-text-field solo placeholder="Введите наименование витрины"
              v-model="name" @change="updateShowCaseName" :disabled="isPreview"></v-text-field>
            </v-card-text>
          </v-card>
          <Configuration
              :template.sync="template"
              :published="published"
              :activeBlockId.sync="activeBlockId"
              :is-preview="isPreview"
              @reload="fetchById"
              @preview="handlePreview"
          />
        </div>
        <Preview :template="template" :activeBlockId="activeBlockId" />
      </v-container>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-row justify="center" align="center" class="mb-5 flex-nowrap">
        <v-col cols="auto">
          <v-btn
            color="primary"
            text
            outlined
            rounded
            @click="$router.go(-1)"
          >Назад</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            color="primary"
            rounded
            @click="submitShowcase"
            v-if="!isPreview"
          >Сохранить</v-btn>
        </v-col>
      </v-row>

    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';
// import banner from '@/views/crm/marketplace/components/banner.vue';
import * as components from '@/views/crm/marketplace/components/index';
import Configuration from './components/Configuration.vue';
import Preview from './components/Preview.vue';
import PreviewModal from '@/views/crm/marketplace/components/PreviewModal.vue';

export default {
  name: 'Card',
  components: {
    PreviewModal,
    Configuration,
    Preview,
    ...components,
  },
  filters: {
    format(val) {
      if (moment(val).isValid()) {
        return moment(val, 'YYYY-MM-DDTHH:mm:ss.sss[Z]').format('DD-MM-YYYY');
      }
      return '';
    },
  },
  data() {
    return {
      loaded: true,
      openedComponent: null,
      dialogVisible: false,
      edit: false,
      catalogs: true,
      activeItem: {},
      parentIndex: null,
      childIndex: null,
      id: null,
      items: [],
      loadDialog: false,
      previousShowcase: null,
      template: [],
      published: false,
      name: null,

      informationalBlock: {},
      showPreview: false,
      activeBlockId: null,
      isPreview: false,
    };
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        this.resetComponent();
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.isPreview = !!this.$route.query?.isPreview
      this.fetchById(this.id);
    }
  },
  computed: {
    paymentTypeEnums() {
      return this.$store.getters['entity/getEntity']('paymentTypeEnums');
    },
  },
  methods: {
    async loadPreviousShowcase() {
      const response = await this.$newCrmService.get('/crm/main-page');
      this.items = response.content;
      this.loadDialog = true;
    },

    async fetchById(id = this.id) {
      this.$loading(true);
      try {
        const res = await this.$newCrmService.get(`/crm/main-page/getById/${id}`);
        if (res) {
          this.template = res.blocks;
          this.published = res.published;
          this.name = res.name
        }
      } catch (e) {
        console.log(e);
      }
      this.$loading(false);
    },
    allowedDates(val) {
      return ![0, 6].includes(moment(val)
        .day());
    },
    resetComponent() {
      this.childIndex = null;
      this.parentIndex = null;
      this.activeItem = {};
      this.openedComponent = null;
    },
    openComponent(el, index, parentIntex) {
      this.activeItem = el.child[index];
      this.childIndex = index;
      this.parentIndex = parentIntex;
      this.openedComponent = this.activeItem.type;
      this.dialogVisible = true;
    },
    handleCloseComponent() {
      this.dialogVisible = false;
    },
    handleSubmit(item) {
      this.activeItem.meta = item;
      const body = {
        id: this.id,
        content: this.template,
      };
      this.$api.put('/api/v1/crm/main-page', body).then(() => {
        this.handleCloseComponent();
      });
    },
    uploadedCount() {
      const catalogs = this.template.find((i) => i.id === 10);
      if (catalogs) {
        if (catalogs.child.every((i) => i.meta)) {
          return true;
        }
      }
      return false;
    },
    // eslint-disable-next-line consistent-return
    submitShowcase() {
      this.$loading(true);
      this.$newCrmService.post(`/crm/main-page/${this.id}/complete`).then(() => {
        this.$router.push({
          name: 'marketplace',
        });
      }).finally(() => {
        this.$loading(false);
      });
    },
    componentHandler(item) {
      if (item.type === 'banner') {
        return Array.isArray(item.meta) ? 'imageCarousel' : 'singleImage';
      }
      return 'productList';
    },
    handlePreview(informationalBlock) {
      this.informationalBlock = { ...informationalBlock }
      this.showPreview = true
    },
    async updateShowCaseName() {
      await this.$newCrmService.put('/crm/main-page', {
        id: +this.id,
        name: this.name,
        content: this.template
      })
    }
  },
};
</script>

<style scoped lang="scss">

.showcase {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 10px;
  &__wrapper {
    flex: 100%;
    background: #EDEDED;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 10px;
    padding-bottom: 20px;
    max-width: 50%;
    overflow: hidden;
  }

  &__name {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    display: flex;
    align-items: center;

    color: #737373;
    padding-bottom: 20px;
  }
}
.card-title {
  height: 70px;
}

.add-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.block {
  border-radius: 20px;
  position:relative;

  img {
    object-fit: contain;
  }

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease;
    opacity: 1;
  }
}
.block:hover {
  border: 1px solid black;
  span {
    opacity: 0;
  }
}
.background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

</style>
