const TextCardDto = {
  text: "htmlText"
};

const QuestionCardDto = {
  question: "question",
  answer: "answer"
};

const ImageCardDto = {
  imageUrl: "imageUrl"
};

const ButtonCardDto = {
  color: "btnColor",
  text: "callToActionText",
  link: "btnType",
  selectedId: "callToAction",
  name: "name"
};

const blockKeys = {
  blockText: 2,
  blockQuestion: 3,
  blockImage: 4,
  blockButton: 5,
  blockTimer: 6
};
const Keys = {
  [blockKeys.blockText]: "blockText",
  [blockKeys.blockQuestion]: "blockQuestion",
  [blockKeys.blockImage]: "blockImage",
  [blockKeys.blockButton]: "blockButton",
  [blockKeys.blockTimer]: "blockTimer"
};

const returnAsArray = [3, 4];

export {
  TextCardDto,
  ButtonCardDto,
  ImageCardDto,
  QuestionCardDto,
  Keys,
  returnAsArray,
  blockKeys
};
