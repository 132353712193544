<template>
  <CardWrapper title="Кнопка" @clear="onClear" :disabled="disabled">
    <template>
      <v-form ref="form">
        <div class="d-flex">
          <v-select
            full-width
            class="pr-1"
            label="Цвет кнопки"
            style="max-width: 50%;"
            :value="currentValue[ButtonCardDto.color]"
            item-text="name"
            item-value="id"
            :items="colorTypes"
            :disabled="disabled"
            @input="onInput(ButtonCardDto.color, $event)"
            :rules="[v => !!v || 'Обязательное поле']"
            clearable
          ></v-select>
          <v-text-field
            class="pl-1"
            full-width
            style="max-width: 50%;"
            label="Текст кнопки"
            maxLength="24"
            counter
            :disabled="disabled"
            :value="currentValue[ButtonCardDto.text]"
            @input="onInput(ButtonCardDto.text, $event)"
            :rules="[v => !!v || 'Обязательное поле']"
            clearable
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-select
            full-width
            class="pr-1"
            label="Привязка"
            :items="linkTypes"
            style="max-width: 50%;"
            item-value="id"
            item-text="name"
            :value="currentValue[ButtonCardDto.link]"
            @input="onInput(ButtonCardDto.link, $event)"
            @change="changeLinkType"
            :rules="[v => !!v || 'Обязательное поле']"
            clearable
            :disabled="disabled"
          ></v-select>
          <v-autocomplete
            full-width
            class="pl-1"
            placeholder="Выбрать товар"
            style="max-width: 50%;"
            v-model="currentValue[ButtonCardDto.selectedId]"
            item-text="textRu"
            item-value="id"
            :items="searchItems"
            no-filter
            return-object
            :rules="[v => !!v || 'Обязательное поле']"
            :search-input.sync="searchInput"
            @change="onInputObject(ButtonCardDto.selectedId, $event)"
            clearable
            hide-no-data
            :disabled="disabled"
          ></v-autocomplete>
        </div>
      </v-form>
    </template>
  </CardWrapper>
</template>

<script>
import CardWrapper from '@/views/crm/marketplace/components/constructorComponents/CardWrapper.vue';
import {
  ButtonCardDto
} from '@/views/crm/marketplace/components/constructorComponents/constuctorDtos';
import { debounce } from 'lodash';

export default {
  name: "ButtonCard",
  components: { CardWrapper },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ButtonCardDto,
      currentValue: {  },
      searchItems: [],
      linkTypes: [
        { name: "Товар", id: 1 },
        { name: "Каталог", id: 2 }
      ],
      colorTypes: [
        { name: "Зеленый", id: "#49BA80" },
        { name: "Желтый", id: "#F2BE33" }
      ],
      searchInput: null,
    };
  },
  model: {
    prop: "value",
    event: "input"
  },
  watch: {
    value: {
      handler(val) {
        if (val?.callToAction && val?.name) {
          this.currentValue = {
            ...val,
            callToAction: +val.callToAction
          };
          this.searchItems = [{ id: +val.callToAction, textRu: val.name }];
        }
      },
      immediate: true
    },
    searchInput(val) {
      if (val && val.toString().indexOf(this.currentValue.name)) {
        this.onSearchItems(val)
      }
    }
  },
  methods: {
    onClear() {
      this.$emit("clear");
    },
    onInput(prop, value) {
      this.currentValue[prop] = value;
      this.$emit("input", this.currentValue);
    },
    onInputObject(prop, value) {
      this.currentValue[prop] = value.id;
      this.currentValue.name = value.nameRu;
      this.$emit("input", this.currentValue);
    },
    onSearchItems: debounce(async function(str) {
      if (this.currentValue[ButtonCardDto.link] === 1) {
        const response = await this.$searchService.get(`/products/search?QueryString=${str}`);
        this.searchItems = response?.content.map(product => {
          return {
            ...product,
            textRu: `${product.nameRu}/${product.color.nameRu}`
          }
        });
      } else {
        const response = await this.$catalogService.get(`/crm-catalogs/search?name=${str}`);
        this.searchItems = response?.map(product => {
          return {
            ...product,
            textRu: `${product.nameRu}`
          }
        });
      }

    }, 500),
    changeLinkType() {
      this.searchItems.splice(0)
      this.currentValue[ButtonCardDto.selectedId] = null
    }
  }
};
</script>

<style scoped></style>
