<template>
  <CardWrapper title="Текст" @clear="onClear" :disabled="disabled">
    <vue-editor
      :editor-toolbar="customToolbar"
      v-model="currentValue[TextCardDto.text]"
      :disabled='disabled'
      @blur="update"
    />
  </CardWrapper>
</template>

<script>
import { VueEditor } from "vue2-editor";
import CardWrapper from "@/views/crm/marketplace/components/constructorComponents/CardWrapper.vue";
import { TextCardDto } from "@/views/crm/marketplace/components/constructorComponents/constuctorDtos";

export default {
  name: "TextCard",
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    CardWrapper,
    VueEditor
  },
  model: {
    prop: "value",
    event: "input"
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val;
      },
      immediate: true
    }
  },
  data() {
    return {
      TextCardDto,
      customToolbar: [
        [{ header: [false, 1, 2, 3] }],
        ["bold", "italic", "underline"],
        [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
        [{ 'color': [] }, { 'background': [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link"]
      ],
      currentValue: null
    };
  },
  methods: {
    update() {
      this.$emit("input", this.currentValue);
    },
    onClear() {
      this.$emit("clear");
    }
  }
};
</script>

<style scoped></style>
