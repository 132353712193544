<template>
  <v-btn
    v-if="Object.keys(value)"
    :color="value.btnColor"
    rounded
    :dark="value.btnColor === '#49BA80'"
  >
    {{value.callToActionText}}
  </v-btn>
</template>

<script>
export default {
  name: "ButtonPreview",
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
};
</script>

<style scoped></style>
