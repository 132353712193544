<template>
  <div class="preview-products">
    <div v-for="(el, index) in item.items" :key="index">
      <div class="preview-products__list">
        <product-card
            class="preview-products__list-item"
            v-for="item in el.products"
            :key="item.id"
            :product="item"/>
      </div>
    </div>

  </div>
</template>

<script>
import ProductCard from '../../../../components/ProductCard.vue';

export default {
  name: 'PreviewProducts',
  components: { ProductCard },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'MOBILE',
    },
  },
};
</script>

<style lang="scss">
.preview-products{
  &__list {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    &-item {
      width: 165px !important;
      height: 314px !important;
      .product-img {
        width: 165px !important;
        height: 220px !important;
        background-size: contain;
      }
      .subtitle {
        display: none !important;
      }
    }
  }
}

</style>
