<template>
  <v-card class="informational-banner">
    <v-card-text>
      <v-form v-model="valid" ref="form">
        <ImageBlockContent
          :disabled="isPreview"
          v-model="informationalBlock.imageUrl"
        ></ImageBlockContent>
        <v-expansion-panels :value="true" class="pb-6">
          <v-expansion-panel>
            <v-card>
              <v-card-title>
                <span class="informational-banner__date-title"> Срок публикации </span>
              </v-card-title>
              <v-card-text>
                <div class="d-flex informational-banner__date-pickers">
                  <DatePicker
                    class="mr-1"
                    label="Дата начала"
                    :min-date="today"
                    :max-date="dayBeforeEnd"
                    :disabled="isPreview"
                    v-model="informationalBlock.startDateTime"
                  ></DatePicker>
                  <DatePicker
                    class="ml-1"
                    v-model="informationalBlock.endDateTime"
                    label="Дата окончания"
                    :disabled="isPreview"
                    :min-date="nextDayAfterStart"
                  ></DatePicker>
                </div>
              </v-card-text>
            </v-card>
          </v-expansion-panel>
        </v-expansion-panels>
        <div class="d-flex flex-column">
          <span class="informational-banner__constructor-title">Конструктор информационного экрана</span>
          <Constructor :items.sync="informationalBlock.items" ref="constructor" :is-preview="isPreview"/>
        </div>

      </v-form>
    </v-card-text>
    <v-card-actions class="justify-space-between">
      <v-spacer/>
      <v-btn
        small
        rounded
        outlined
        color="primary"
        style="border-color: #007450"
        @click="handlePreview"
      >
        Превью
      </v-btn>
      <v-btn
        small @click="handleSaveContent" color="primary"
        rounded
        v-if="!isPreview"
             >
        Сохранить контент
      </v-btn>

    </v-card-actions>
  </v-card>
</template>

<script>
import ImageBlockContent from '@/views/crm/marketplace/components/ImageBlockContent.vue';
import DatePicker from '@/components/DatePicker.vue';
import Constructor from '@/views/crm/marketplace/components/Constructor.vue';
import {
  blockKeys,
  Keys,
  returnAsArray
} from '@/views/crm/marketplace/components/constructorComponents/constuctorDtos';
import moment from 'moment';

export default {
  name: 'Informational',
  components: {
    Constructor,
    DatePicker,
    ImageBlockContent
  },
  props: {
    mainPageBlockId: {
      type: Number
    },
    content: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
    },
    mainPageId: {
      type: Number,
    },
    isPreview: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      valid: null,
      informationalBlock: {
        imageUrl: null,
        startDateTime: null,
        endDateTime: null,
        items: [],
      },
      today: moment().format('YYYY-MM-DD')
    }
  },
  watch: {
    content: {
      handler(val) {
        if (val.length > 0) {
          this.informationalBlock = val[0]
          this.informationalBlock.startDateTime = this.informationalBlock.startDateTime ? moment(this.informationalBlock.startDateTime).format('YYYY-MM-DD') : null
          this.informationalBlock.endDateTime = this.informationalBlock.endDateTime ?  moment(this.informationalBlock.endDateTime).format('YYYY-MM-DD'): null
        }
      },
      immediate: true,
    }
  },
  computed: {
    nextDayAfterStart() {
      return this.informationalBlock?.startDateTime ? moment(this.informationalBlock?.startDateTime, 'YYYY-MM-DD')
        .add(1, 'day')
        .format('YYYY-MM-DD') : moment(new Date())
        .format('YYYY-MM-DD')
    },
    dayBeforeEnd() {
      return this.informationalBlock?.endDateTime ? moment(this.informationalBlock.endDateTime, 'YYYY-MM-DD').subtract(1, 'day').format('YYYY-MM-DD') : null
    }
  },
  methods: {
    handleRemoveContent() {
      this.$emit('removeContent', {
        id: this.id,
      })
    },
    async validations() {
      let isSuccess = true
      if (!this.$refs.form.validate()) {
        this.$toast.warning('Заполните поля');
        isSuccess = false
      }
      if (!this.informationalBlock.imageUrl) {
        this.$toast.warning('Загрузите картинку для мобильных устройств');
        isSuccess = false
      }


      if (this.$refs.constructor.$refs?.buttonCard) {
        const buttonCardValidation = []

        this.$refs.constructor.$refs?.buttonCard.forEach(card => {
          buttonCardValidation.push(card.$refs.form.validate())
        })
        if (buttonCardValidation.some(valid => !valid)) {
          isSuccess = false
        }
      }

      if (this.$refs.constructor.$refs?.imageCard) {
        const imageCardValidation = []

        this.$refs.constructor.$refs?.imageCard.forEach(card => {
          imageCardValidation.push(!!card.currentValue?.imageUrl)
        })
        if (imageCardValidation.some(valid => !valid)) {
          this.$toast.warning('Заполните картинку');
          isSuccess = false
        }
      }

      if (this.$refs.constructor.$refs?.textCard) {
        const textCardValidation = []

        this.$refs.constructor.$refs?.textCard.forEach(card => {
          textCardValidation.push(!!card.currentValue?.htmlText)
        })
        if (textCardValidation.some(valid => !valid)) {
          this.$toast.warning('Заполните текстовое поле');
          return false
        }
      }

      if (this.$refs.constructor.$refs?.questionCard) {
        const questionCardValidation = []

        this.$refs.constructor.$refs?.questionCard.forEach(card => {
          questionCardValidation.push(card.$refs.form.validate())
        })
        if (questionCardValidation.some(valid => !valid)) {
          return false
        }
      }
      const constructorItems = this.$refs.constructor.currentItems
      if (!constructorItems.length) {
        this.$toast.warning('Конструктор не заполнен');
        return false
      }

      return isSuccess
    },
    async handleSaveContent() {
      const valid = await this.validations()
      if (!valid) {
        return false
      }

      const constructorItems = this.$refs.constructor.currentItems
      const body = {
        ...this.informationalBlock,
        mainPageBlockId: this.mainPageBlockId,
        mainPageId: this.mainPageId,
        items: constructorItems.map((item, index) => {
          const obj = {
            contentType: item.type,
            ord: index,
            [Keys[item.type]]: this.transformPayload(item.value, item.type)
          }
          if (item.type === blockKeys.blockTimer) {
            delete obj[Keys[item.type]]
          }

          return obj
        })
      }

      this.$emit('saveContent', body, true)

      return true;
    },

    transformPayload(payload, type) {
      if (returnAsArray.includes(type)) {
        return [{...payload}]
      }

      return payload
    },
    handlePreview() {
      this.$emit('preview', this.informationalBlock)
    }
  }
};
</script>

<style scoped lang="scss">
.informational-banner {
  &__constructor-title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  &__date-title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #333333;

  }
  &__date-pickers {
    gap: 8px;
  }
}
</style>
