<template>
  <CardWrapper title="Таймер" @clear="onClear" :disabled="disabled"> </CardWrapper>
</template>

<script>
import CardWrapper from "@/views/crm/marketplace/components/constructorComponents/CardWrapper.vue";

export default {
  name: "TimerCard",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: { CardWrapper },
  methods: {
    onClear() {
      this.$emit("clear");
    }
  }
};
</script>

<style scoped></style>
