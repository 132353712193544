<template>
  <div class="mobile-preview">
    <div class="mobile-preview__mock">
      <v-img
          :src="require('@/assets/marketplace-mobile-mock.png')"
          transition="scale-transition"
          height="100%"
      />
    </div>
    <div class="mobile-preview__content">
      <v-img
        v-if="activeBanner"
        class="mobile-preview__banner"
        :src="generateImageUrl(activeBanner)"
        width="140"
        max-width="140"
        max-height="72"
        height="72"
        style="border-radius: 8px;"
        contain
      >

      </v-img>
      <div v-else class="mobile-preview__empty">
        <v-img
          :src="require(`@/assets/icons/picture.svg`)"
          transition="scale-transition"
          contain
          height="24"
          width="24"
        />
        <span>
          Баннеров пока нет
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import { generateImageUrl } from '@/utils/helper';
export default {
  name: 'MobilePreview',
  components: {
  },
  props: {
    template: {
      type: Array,
      default: () => [],
    },
    activeBlockId: {
      type: Number
    }
  },
  computed: {
    activeBanner() {
      if (this.activeBlockId) {
        const template = this.template.find(item => item.id === this.activeBlockId)
        if ([0,6].includes(template?.type)) {
          if (template?.informationalBlockItems?.length) {
            return template?.informationalBlockItems[0].imageUrl
          }

          if (template?.items.length > 0) {
            const mobileImage = template.items[0].images.find(image => image.type === 'MOBILE')
            return mobileImage.imageUrl
          }
        }
      }
      return null
    },
    products() {
      return this.template.filter(i => i.type === 2)
    }
  },
  methods: {
    generateImageUrl,
  },
};
</script>

<style scoped lang="scss">
.mobile-preview {
  width: 375px;
  display: flex;
  flex-direction: column;
  background: #F4F4F4;
  gap: 10px;
  position: relative;
  height: 812px;
  &__mock {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }

  &__content {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
  &__banner {
    top: 348px;
    left: 170px;
    z-index: 1;
    position: absolute;
  }
  &__empty {
    @extend .mobile-preview__banner;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 140px;
    height: 72px;
    background: #F4F3F3;
    border: 1px solid #DADADA;
    border-radius: 8px;

    span {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      color: #636463;
    }
  }
}
</style>
<style lang="scss">
.block-title {
  font-family: 'Raleway', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #666666;
}
</style>
