<template>
  <div class="configuration">
    <v-expansion-panels
        class="template"
        v-model="panel"
        @change="handleChangeBlock"
    >
      <v-expansion-panel
          v-for="(item, i) in template"
          :key="i"
          class="template__item">
        <v-expansion-panel-header class="template__item-title">
          <span> {{ getTitle(item.type) }}</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <component
            :is="getComponent(item)"
            :mainPageBlockId="item.id"
            :content="item.type === 6 ? item.informationalBlockItems : item.items"
            :type="item.type"
            :block="item"
            :mainPageId="mainPageId"
            :is-preview="isPreview"
            @saveContent="handleSaveBlockContent"
            @removeContent="handleRemoveBlockContent"
            @createFirstContent="handleCreateBlockContent(item)"
            @updateBlock="handleBlockUpdate"
            @preview="handleOpenPreview"
          ></component>
        </v-expansion-panel-content>
        <div class="template__item-actions" v-if="panel === i">
          <v-btn
              outlined text
              rounded
              v-if="!isPreview"
              @click="handleRemoveComponent(item)"
          >
           Удалить блок
          </v-btn>
          <v-btn
              outlined color="primary"
              rounded
              v-if="!isPreview"
              @click="handleCreateBlockContent(item)"
              :disabled="disableAddContentButton(item)"
          >
            Добавить контент
          </v-btn>
        </div>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-menu
        offset-y
        bottom
        v-if="!isPreview"

    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="#007450"
            dark
            max-width="225"
            v-bind="attrs"
            v-on="on"
            rounded
            outlined
            class="mb-8"

        >
          Добавить блок
        </v-btn>
      </template>

      <v-list>
        <v-list-item
            v-for="(item, index) in blocks"
            :key="index"
            @click="addMainPageBlock(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import * as components from './index';

export default {
  name: 'Configuration',
  props: {
    template: {
      type: Array,
      default: () => [],
    },
    published: {
      type: Boolean,
      default: false,
    },
    activeBlockId: {
      type: Number,

    },
    isPreview: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    ...components,
  },
  data() {
    return {
      blocks: [
        {
          title: 'Рекламный баннер', type: 0, component: 'advertisement',
        },
        {
          title: 'Блок товары', type: 2, component: 'productblock',
        },
        {
          title: 'Информационный баннер', type: 6, component: 'informational', maxContentLimit: 1
        },
      ],
      mainPageId: null,
      panel: null,
    };
  },
  created() {
    this.mainPageId = +this.$route.params.id;
  },
  methods: {
    getTitle(type) {
      return this.blocks.find((i) => i.type === type)?.title;
    },

    async addMainPageBlock(component) {
      if (this.published) {
        this.$toast.warning('Нельзя редактировать. Витрина опубликована');
        return;
      }
      this.$loading(true);
      try {
        const item = {
          name: component.title,
          type: component.type,
          mainPageId: this.mainPageId,
        };
        const response = await this.$newCrmService.post('/crm/main-page-block', item);
        if (response.content) {
          this.$emit('reload');
        }
      } catch (e) {
        this.handleErrorNotification(e);
      }
      this.$loading(false);
    },
    getComponent(item) {
      const find = this.blocks.find((i) => i.type === item.type);
      if (find) {
        return find.component;
      }

      return '';
    },
    async handleRemoveComponent(component) {
      if (this.published) {
        this.$toast.warning('Нельзя редактировать. Витрина опубликована');
        return;
      }
      this.$loading(true);
      try {
        await this.$newCrmService.delete(`/crm/main-page-block/${component.id}`);
        this.$emit('reload');
      } catch (e) {
        this.handleErrorNotification(e);
      }
      this.$loading(false);
    },
    async prepareFormData(content) {
      const formData = new FormData();
      const thisContent = content;
      if (content.files) {
        delete thisContent.url;
      }

      const deletableKeys = ['meta', 'metaCatalog'];

      deletableKeys.forEach((i) => {
        delete thisContent[i];
      });

      Object.entries(thisContent).forEach(([key, value]) => {
        if (value || typeof value === 'number') {
          if (Array.isArray(value)) {
            value.forEach((i) => {
              formData.append(key, i);
            });
          } else {
            formData.append(key, value);
          }
        }
      });

      return formData;
    },
    async urlToFile(url, name) {
      const res = await fetch(url);
      const blob = await res.blob();
      return new File([blob], name, { type: blob.type });
    },
    async handleSaveBlockContent(content, isInformational = false) {
      if (this.published) {
        this.$toast.warning('Нельзя редактировать. Витрина опубликована');
        return;
      }
      this.$loading(true);
      try {
        let response
        if (!isInformational) {
          const formData = await this.prepareFormData(content);
          response = await this.$newCrmService.put('/crm/main-page-block-content/', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          response = await this.$newCrmService.post('/crm/main-page-block-content/informational', content)
        }
        if (response.content || (isInformational && response.succeeded)) {
          this.$emit('reload');
          if (this.response?.succeeded) {
            this.$toast.success('Выполнено успешно');
          }
        }
      } catch (e) {
        this.handleErrorNotification(e);
      }
      this.$loading(false);
    },
    async handleCreateBlockContent({ id }) {
      if (this.published) {
        this.$toast.warning('Нельзя редактировать. Витрина опубликована');
        return;
      }
      this.$loading(true);
      try {
        const body = { mainPageBlockId: id };
        const formData = await this.prepareFormData(body);
        const response = await this.$newCrmService.post('/crm/main-page-block-content/', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response.content) {
          this.$emit('reload');
          if (response?.succeeded) {
            this.$toast.success('Выполнено успешно');
          }
        }
      } catch (e) {
        this.handleErrorNotification(e);
      }
      this.$loading(false);
    },
    async handleRemoveBlockContent(content) {
      if (this.published) {
        this.$toast.warning('Нельзя редактировать. Витрина опубликована');
        return;
      }
      this.$loading(true);
      try {
        const response = await this.$newCrmService.delete(`/crm/main-page-block-content/${content.id}`);
        if (response?.succeeded) {
          this.$emit('reload');
          this.$toast.success('Выполнено успешно');
        }
      } catch (e) {
        this.handleErrorNotification(e);
      }
      this.$loading(false);
    },
    async handleBlockUpdate(block) {
      if (this.published) {
        this.$toast.warning('Нельзя редактировать. Витрина опубликована');
        return;
      }
      this.$loading(true);
      try {
        await this.$newCrmService.put('/crm/main-page-block/', block);
      } catch (e) {
        this.handleErrorNotification(e);
      }
      this.$loading(false);
    },
    handleErrorNotification(error) {
      const currentError = error?.response?.data?.Message;
      if (currentError) {
        if (Array.isArray(currentError)) {
          currentError.forEach((i) => {
            this.$toast.warning(i);
          });
        } else {
          this.$toast.warning(currentError);
        }
      }
    },
    disableAddContentButton({type, items, informationalBlockItems: infoItems}) {
      const blockConfig = this.blocks.find(block => block.type === type)
      if (!blockConfig) {
        return true
      }
      if (!blockConfig.maxContentLimit) {
        return false
      }

      if (items.length > 0 && items.length >= blockConfig.maxContentLimit) {
        return true
      }

      return infoItems.length > 0 && infoItems.length >= blockConfig.maxContentLimit;
    },
    handleOpenPreview(informationBlockItems) {
      this.$emit('preview', informationBlockItems)
    },
    handleChangeBlock() {
      this.$emit('update:active-block-id', this.template[this.panel]?.id)
    }
  },
};
</script>

<style lang="scss">
.configuration {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  padding-top: 20px;
  gap: 40px;
  .template {
    &__item {
      &-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
          font-family: Montserrat, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: #737373;
        }
      }
      &-actions {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background: #FFFFFF;
        padding: 20px;
        gap: 20px;
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background: gray;
          top: 0;
          left: 0;
        }
      }
    }
  }
}
</style>
