<template>
  <div class="image-block-content">
    <div class="image-block-content__images">
      <div
        class="image-block-content__image"
      >
        <div v-if="!value" class="image-block-content__placeholder" >
          <v-btn  @click="upload" width="100%" height="100%" text :disabled="disabled">
            <v-icon   x-large color="#FFFFFFF">
              mdi-image-plus
            </v-icon>
          </v-btn>
        </div>
        <v-img v-else height="130" contain
               :src="generateImageUrl(value)">
          <v-menu>
          </v-menu>
          <v-btn absolute x-small fab text style="right: 0;"
                 :disabled="disabled"
                 @click="updateImage(null)"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-img>
      </div>
    </div>
    <CmImageUploader
      style="display: none;"
      no-crop
      :hideButton="true" :id="`file-input-${id}`" @set="handleImage"/>
  </div>
</template>

<script>
import mixin from '@/views/crm/marketplace/components/mixin';

export default {
  name: 'ImageBlockContent',
  mixins: [mixin],
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: String.prototype.padStart(6, Math.floor(Math.random() * 1000))
    }
  },
  methods: {
    updateImage(value) {
      this.$emit(`input`, value)
    },
    upload() {
      document.querySelector(`#file-input-${this.id}`).click();
    },
    async handleImage({ file }) {
      const formData = new FormData()
      formData.append('file', file)
      const url = await this.uploadImageFile(formData)
      this.updateImage(url)
    },

  }
};
</script>

<style scoped lang="scss">
.image-block-content {
  &__images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }
  &__image {
    width: 50%;
    border: 1px solid #FFFFFF;
  }
  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 130px;
    background: linear-gradient(0deg, #C4C4C4, #C4C4C4);
    i {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      height: 100%;
    }
  }
}
</style>
