<template>
  <div :class="['advertisement']" >
    <AdvertisementBanner
        v-for="item in content"
        :key="item.id"
        :mainPageBlockId="mainPageBlockId"
        :content="item"
        :disabled="isPreview"
        show-product-url
        show-catalog-id
        @saveContent="handleSaveContent"
        @removeContent="handleRemoveContent"
    />
  </div>
</template>

<script>
import AdvertisementBanner from './AdvertisementBanner.vue';

export default {
  name: 'Advertisement',
  components: { AdvertisementBanner },
  props: {
    mainPageBlockId: {

    },
    content: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Number,
    },
    isPreview: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    handleSaveContent(content) {
      this.$emit('saveContent', content);
    },
    handleRemoveContent(content) {
      this.$emit('removeContent', content);
    },
  },
};
</script>

<style lang="scss">
.advertisement {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &__body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    height: 100%;
    width: 100%;

  }
}

</style>
