<template>
    <div class="background" :style="`background-image: url(${items.url});
                    height: ${height}`">
    </div>
</template>

<script>

import mixin from '@/views/crm/marketplace/components/mixin';

export default {
  name: 'singleImage',
  mixins: [mixin],
  props: {
    height: {
      default: '480px',
    },
    items: {
      default: () => {},
    },
  },
  watch: {
    'items.imagePath': {
      handler(val) {
        if (val) {
          this.getImage(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getImage(path) {
      const url = await this.getImageByPath(path);
      this.$set(this.items, 'url', url);
    },
  },
};
</script>

<style scoped>

</style>
