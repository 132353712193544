<template>
  <v-card>
    <v-card-title>
      <v-icon class="handle">mdi-drag-variant</v-icon>
      <span>{{ title }}</span>
      <v-spacer></v-spacer>
      <v-btn rounded x-small text fab @click="onClear" :disabled="disabled">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "CardWrapper",
  props: {
    title: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onClear() {
      this.$emit("clear");
    }
  }
};
</script>

<style scoped></style>
