<template>
  <div class="timer-preview">
    <div class="timer-preview__days">
      <div class="timer-preview__date">
        <span class="timer-preview__date-text">{{days}}</span>
      </div>
      <span class="timer-preview__text">дн</span>
    </div>
    <div class="timer-preview__divider">
      :
    </div>
    <div class="timer-preview__hours">
      <div class="timer-preview__date">
        <span class="timer-preview__date-text">{{hours}}</span>
      </div>
      <span class="timer-preview__text">часов</span>
    </div>
    <div class="timer-preview__divider">
      :
    </div>
    <div class="timer-preview__minutes">
      <div class="timer-preview__date">
        <span class="timer-preview__date-text">{{minutes}}</span>
      </div>
      <span class="timer-preview__text">минут</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "TimerPreview",
  props: {
    block: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      days: null,
      hours: null,
      minutes: null,
    }
  },
  created() {
    this.splitDate()
  },
  methods: {
    splitDate() {
      const now = moment()
      const endDate = moment(this.block.endDateTime, 'YYYY-MM-DD')
      const diff = endDate.diff(now)
      if (diff < 0) {
        return
      }

      this.days = endDate.diff(now, 'days', false)
      this.hours = moment(diff).hours() + 6
      this.minutes = moment(diff).minutes()
      setTimeout(() => {
        this.splitDate()
      }, 1000 * 60)
    }
  }
};
</script>

<style scoped lang="scss">
.timer-preview {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  background: rgba(73, 186, 128, 0.2);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  &__divider {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #1B1B1B;
    margin-top: -20px;
  }
  &__days, &__hours, &__minutes {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    justify-content: center;
  }
  &__date {
    background: rgba(73, 186, 128, 0.2);
    padding: 8px;
    border: 2px solid #045530;
    border-radius: 56px;
    background-clip: padding-box;
    width: 64px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: '';
      position: absolute;
      border-radius: inherit;
      z-index: -1;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background: linear-gradient(122.82deg, #045530 0%, #30B478 0.01%, #1C0E35 112.25%);


    }
  }
  &__date-text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    background: linear-gradient(122.82deg, #045530 0%, #30B478 0.01%, #1C0E35 112.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  &__text {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    text-align: center;

    /* primary */

    color: #1B1B1B;

  }
}
</style>
