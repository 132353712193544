<template>
  <v-expansion-panels class="question-preview" v-if="question" active-class="active">
    <v-expansion-panel>
      <v-expansion-panel-header style="padding-left: 10px;">
        <span class="question-preview__question"> {{question}} </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <span class="question-preview__answer" v-html="answer" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: "QuestionPreview",
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      question: null,
      answer: null
    }
  },
  created() {
    this.question = this.value[0]?.question
    this.answer = this.value[0]?.answer
  }
};
</script>

<style scoped lang="scss">
.active {
  background: #F4F3F3 !important;
}
.question-preview {
  &__question {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1B1B1B;
    overflow-wrap: anywhere;
  }
  &__answer {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #636463;
    white-space: pre-wrap;

  }
}
</style>
