<template>
  <div
    class="d-flex flex flex-column constructor ">
    <draggable
      class="d-flex pa-4 flex-wrap"
      :list="template"
      :group="{ name: 'people', pull: 'clone', put: false }"
      style="background-color: white;"
      :sort="false"
      :clone="cloneElement"
      @change="log"
      :options="{disabled : isPreview}"
    >
      <div
        class="constructor__element pa-4 mr-2 "
        v-for="element in template"
        :style="editBlocked && 'cursor: not-allowed'"
        :key="element.type"
      >
        <v-icon color="white">{{element.icon}}</v-icon>
        {{ element.name }}
      </div>
    </draggable>
    <div class="flex overflow-auto constructor__wrapper" style="position: relative">
      <div
        v-if="currentItems?.length === 0"
        class="constructor__area"
        >
        <span class="valign">Перетащите нужный блок конструктора к эту область</span>
      </div>
      <div class="constructor__drop-area">
        <draggable
          v-model="currentItems"
          group="people"
          @change="log"
          handle=".handle"
          :options="{disabled : isPreview}"

        >
          <div
            v-for="(item, idx) in currentItems"
            :key="`${item.index}${idx}`"
            class="pa-4"
          >
            <component
              :is="item.component"
              v-model="item.value"
              @clear="onClear(idx)"
              :disabled="isPreview"
              :ref="item.component"
            ></component>
          </div>

        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import * as components from './constructorComponents/index'
import { Keys } from '@/views/crm/marketplace/components/constructorComponents/constuctorDtos';
let elementId = 0;

export default {
  name: 'Constructor',
  props: {
    items: {
      type: Array,
      default: () => ([])
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  components: {
    draggable,
    ...components,
  },
  watch: {
    items: {
      handler(val) {
       if (val?.length > 0 && !this.currentItems.length) {
         this.prepareDragZone(val)
       }
      },
      immediate: true,
      deep: true,

    }
  },
  data() {
    return {
      template: [
        {
          type: 2,
          name: 'Текстовое поле',
          icon: 'mdi-text-box-plus',
          component: 'textCard'
        },
        {
          type: 3,
          name: 'Вопрос/Ответ',
          icon: 'mdi-playlist-plus',
          component: 'questionCard'

        },
        {
          type: 4,
          name: 'Картинка',
          icon: 'mdi-image-plus',
          component: 'imageCard'
        },
        {
          type: 5,
          name: 'Кнопка',
          icon: 'mdi-link-plus',
          component: 'buttonCard'

        },
        {
          type: 6,
          name: 'Таймер',
          icon: 'mdi-timer-plus-outline',
          component: 'timerCard'

        },
      ],
      currentItems: [],
    }
  },
  computed: {
    editBlocked() {
      return false
    }
  },
  methods: {
    cloneElement({ type, name, component }) {
      elementId += 1;
      return {
        type,
        name,
        component,
        index: elementId,
        title: '',
        value: {},
      };
    },
    onClear(index) {
      this.currentItems.splice(index, 1)
      this.$emit('update:items', this.currentItems)
    },
    prepareDragZone(items) {
      const currentItems = []
      items.forEach(item => {
        const template = this.template.find(el => el.type === item.contentType)
        currentItems.push({
          ...item,
          type: item.contentType,
          index: item.ord,
          value: this.transformValue(item[Keys[item.contentType]]),
          component: template.component,
          name: template.name
        })
      })
      this.currentItems = [...currentItems]
    },
    log() {},
    transformValue(value) {
      if (Array.isArray(value)) {
        return value[0]
      }

      return value
    }
  }
};
</script>

<style scoped lang="scss">
.constructor {
  &__element {
    background-color: #2d4d76;
    color: white;
    border: 1px dashed white;
    border-radius: 16px;
    cursor: move;
  }
  &__wrapper {
    min-height: 180px;
  }
  &__area {
    width: 100%;
    height: 180px;
    text-align: center;
    border: 1px dashed black;
    background: #CCCCCC;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  &__drop-area {
    background: #CCCCCC;
    min-height: 180px;
  }
}
</style>
