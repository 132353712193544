<template>
  <div class="six-catalogs">
    <v-text-field
      solo
      placeholder="Введите наименование блока"
      v-model="name"
    >

    </v-text-field>
    <div class="advertisement">
      <AdvertisementBanner
          v-for="item in content"
          :key="item.id"
          :mainPageBlockId="mainPageBlockId"
          :content="item"
          show-name
          show-catalog-id
          @saveContent="handleSaveContent"
          @removeContent="handleRemoveContent"
          expanded
      />
    </div>
  </div>
</template>

<script>
import AdvertisementBanner from './AdvertisementBanner.vue';

export default {
  name: 'SixCatalogs',
  props: {
    mainPageBlockId: {

    },
    content: {
      type: Array,
      default: () => [],
    },
    block: {
      type: Object,
      default: () => {},
    },
  },
  components: { AdvertisementBanner },
  data() {
    return {
      name: null,

    };
  },
  watch: {
    block: {
      handler(val) {
        if (val) {
          this.name = val.name;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSaveContent(content) {
      if (this.block.name && this.block.name !== this.name) {
        const { id, type } = this.block;
        const body = {
          id,
          type,
          name: this.name,
        };
        this.$emit('updateBlock', body);
      }
      const body = {
        ...content,
        contentType: 1,
      };
      this.$emit('saveContent', body);
    },
    handleRemoveContent(content) {
      this.$emit('removeContent', content);
    },
  },
};
</script>

<style lang="scss">
.six-catalogs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 600px;

  .advertisement {
    flex-direction: row;
    flex-wrap: wrap;
    &-content {
      max-width: 31%;
      &__type-actions {
        flex-wrap: wrap;
        flex-direction: column;
        padding: unset;
        gap: 10px;
      }
    }
  }
}
</style>
