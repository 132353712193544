<template>
  <CardWrapper title="Вопрос/ответ" @clear="onClear" :disabled="disabled">
    <template>
      <v-form ref="form" v-model="valid">
        <v-text-field
          label="Вопрос"
          :value="currentValue[QuestionCardDto.question]"
          @input="onInput(QuestionCardDto.question, $event)"
          :rules="[v => !!v || 'Обязательное поле']"
          maxLength="72"
          counter
          clearable
          :disabled="disabled"
        >
        </v-text-field>
        <v-textarea
          label="Ответ"
          auto-grow
          :value="currentValue[QuestionCardDto.answer]"
          @input="onInput(QuestionCardDto.answer, $event)"
          :rules="[v => !!v || 'Обязательное поле']"
          clearable
          :disabled="disabled"
        >
        </v-textarea>

      </v-form>
    </template>
  </CardWrapper>
</template>

<script>
import CardWrapper from "@/views/crm/marketplace/components/constructorComponents/CardWrapper.vue";
import { QuestionCardDto } from "@/views/crm/marketplace/components/constructorComponents/constuctorDtos";

export default {
  name: "QuestionCard",
  components: { CardWrapper },
  props: {
    value: {
      type: [Object, String],
      default: () => ({
        question: "",
        answer: ""
      })
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      QuestionCardDto,
      currentValue: null,
      valid: false
    };
  },
  model: {
    prop: "value",
    event: "input"
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val;
      },
      immediate: true
    }
  },
  methods: {
    onClear() {
      this.$emit("clear");
    },
    onInput(prop, value) {
      this.currentValue[prop] = value;
      this.$emit("input", this.currentValue);
    }
  }
};
</script>

<style scoped></style>
