import getEnv from '@/utils/env';

export const refundTemplateTop = '<p style="text-align: center; font-size: xx-large"><strong>&laquo;Clever&raquo;</strong></p>\n'
  + '<p><span style="font-weight: 400;">От Ф.И.О. ___________________________________________________</span></p>\n'
  + '<p><span style="font-weight: 400;">Удостоверение №______________________</span></p>\n'
  + '<p><span style="font-weight: 400;">Кем выдано ___________________________</span></p>\n'
  + '<p><span style="font-weight: 400;">Дата выдачи &laquo;___&raquo; ___________________ 20__г.</span></p>\n'
  + '<p><span style="font-weight: 400;">Заявление на возврат товара.&nbsp;</span></p>\n'
  + '<h1 style="color: #5e9ca0;">&nbsp;</h1>\n'
  + '<p><span style="font-weight: 400;">В &laquo;Clever&raquo; был приобретен товар по заказу №___________</span></p>\n'
  + '<h1 style="color: #5e9ca0;">&nbsp;</h1>';

export const getRefundTemplateTop = (fullName, docNumber, docIssuedBy, docDate, orderId) => (
  '<p style="text-align: center; font-size: x-large"><strong>Заявление на возврат</strong></p>\n'
  + '<p><span style="font-weight: 400;">От: '
  + `${fullName}`
  + '</span></p>\n'
  + '<p><span style="font-weight: 400;">Удостоверение №'
  + `${docNumber}`
  + '</span></p>\n'
  + '<p><span style="font-weight: 400;">Кем выдано: '
  + `${docIssuedBy}`
  + '</span></p>\n'
  + '<p><span style="font-weight: 400;">Дата выдачи: '
  + `${docDate}`
  + '</span></p>\n'
  + '<h1 style="color: #5e9ca0;">&nbsp;</h1>\n'
  // + '<p style="text-align: center"><span style="font-weight: 700;">
  // Заявление на возврат товара</span></p>\n'
  + '<p><span style="font-weight: 400;">В &laquo;Clever&raquo; был приобретен товар по заказу(-ам) № '
  + `${orderId}`
  + '</span></p>\n'
  + '<h1 style="color: #5e9ca0;">&nbsp;</h1>'
);
export const tableHeader = '<tr style="height: 64px;">\n'
  + '<td style="width: 4%; height: 64px;">\n'
  + '    <p><strong>№</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 23%; height: 64px;">\n'
  + '    <p><strong>Наименование товара</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 12%; height: 64px;">\n'
  + '    <p><strong>Артикул</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 15%; height: 64px;">\n'
  + '    <p><strong>Штрихкод</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 13%; height: 64px;">\n'
  + '    <p><strong>Количество</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 14%; height: 64px;">\n'
  + '    <p><strong>Стоимость</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 19%; height: 64px;">\n'
  + '    <p><strong>Причина возврата</strong></p>\n'
  + '</td>\n'
  + '    </tr>';

export const refundTemplateFooter = '<h1 style="color: #5e9ca0;">&nbsp;</h1>\n'
  + '<p><span style="font-weight: 400;">Прошу вернуть мне деньги следующим способом (выбрать):</span></p>\n'
  + '<ul>\n'
  + '    <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">На платежную карту</span></li>\n'
  + '</ul>\n'
  + '<h1 style="color: #5e9ca0;">&nbsp;</h1>\n'
  + '<p><span style="font-weight: 400;">Необходимо приложить копию удостоверения личности.</span></p>\n'
  + '<h1 style="color: #5e9ca0;">&nbsp;</h1>\n'
  + '<p><span style="font-weight: 400;">Дата '
  + `${new Date(Date.now()).toLocaleString().split(',')[0]}`
  + '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;'
  + '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;'
  + '&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;'
  + 'Подпись _________________</span></p>\n'
  + '<h1 style="color: #5e9ca0;"><br /><br /><br /></h1>\n'
  + '<p><span style="font-weight: 400;">Внимание! Вы можете вернуть товар в течение 14 дней после получения заказа. Возврат товара возможен в случае, если сохранены его товарный вид, потребительские свойства, все ярлыки и бирки, а также документ, подтверждающий факт и условия покупки указанного товара.</span></p>';

export const refundTemplateStyle = '<style>'
  + 'body { width: 21cm; padding: 0 1.5cm 0 2cm }'
  + 'table, th, td {\n'
  + '  border: 1px solid black;\n'
  + '  border-collapse: collapse;\n'
  + '}'
  + '</style>';

export const getOrderTemplateTop = (id, date, fullName, phone, address, totalPrice) => (
  '<p style="text-align: center; font-size: large;">'
  + '<strong>ОТБОР</strong></p>\n'
  + '<p><span style="font-weight: 400;">Номер заказа: '
  + `<strong>${id}</strong>`
  + '</span></p>\n'
  + '<p><span style="font-weight: 400;">Дата и время: '
  + `<strong>${date}</strong>`
  + '</span></p>\n'
  + '<p><span style="font-weight: 400;">Клиент: '
  + `<strong>${fullName}</strong>`
  + '</span></p>\n'
  + '<p><span style="font-weight: 400;">Номер телефона: '
  + `<strong>${phone}</strong>`
  + '</span></p>\n'
  + '<p><span style="font-weight: 400;">Адрес доставки: '
  + `<strong>${address}</strong>`
  + '</span></p>\n'
  + '<hr/>\n'
  + '<h1 style="color: #5e9ca0;">&nbsp;</h1>'
  + '<p style="text-align: end"><span style="font-weight: 700;">Итоговая сумма: '
  + `${totalPrice}`
  + '</span></p>\n'
);
export const orderTableHeader = '<tr style="height: 64px;">\n'
  + '<td style="width: 4%; height: 56px;">\n'
  + '    <p><strong>№</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 23%; height: 64px;">\n'
  + '    <p><strong>Фото</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 23%; height: 64px;">\n'
  + '    <p><strong>Наименование товара</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 12%; height: 64px;">\n'
  + '    <p><strong>Штрихкод товара</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 15%; height: 64px;">\n'
  + '    <p><strong>Кол-во заказано</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 13%; height: 64px;">\n'
  + '    <p><strong>Цена за товар</strong></p>\n'
  + '</td>\n'
  + '<td style="width: 14%; height: 64px;">\n'
  + '    <p><strong>Стоимость заказано</strong></p>\n'
  + '</td>\n'
  + '</tr>';

export const orderTemplateFooter = '<h1 style="color: #5e9ca0;">&nbsp;</h1>\n'
  + '<h1 style="color: #5e9ca0;">&nbsp;</h1>\n'
  + '<p><span style="font-weight: 400;">Ответственный ____________________</span></p>\n'
  + '<h1 style="color: #5e9ca0;"><br /><br /><br /></h1>\n';

export default {
  refundTemplateTop,
  tableHeader,
  refundTemplateFooter,
  refundTemplateStyle,
  orderTableHeader,
};


export const formatPhoneNumber = (str) => {
  const cleaned = `${str}`.replace(/\D/g, '');
  const match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{2})(\d{2})$/);

  if (match) {
    const intlCode = match[1] ? '+7 ' : '';
    return [
      intlCode,
      match[2],
      ' ',
      match[3],
      ' ',
      match[4],
      ' ',
      match[5],
    ].join('');
  }

  return null;
};

export const generateImageUrl = (url) => {
  if (!url) {
    return
  }

  if (url.indexOf('data:') > -1) {
    return url;
  }
  const splitted = url.split('/');
  const name = splitted.splice(splitted.length - 1, 1)[0];
  const path = splitted.join('/');
  return `${getEnv('VUE_APP_URL_MINIO')}/ecom/${path}/M_${name}`;
}
