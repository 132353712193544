<template>
  <div class="catalog-product-list">
    <product-card
        class="catalog-product-list__item"
        v-for="i in items"
        :key="i.id"
        :product="i"></product-card>
  </div>
</template>

<script>
import ProductCard from '../../../../components/ProductCard.vue';

export default {
  name: 'CatalogProductList',
  components: { ProductCard },
  props: {
    catalogId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    catalogId: {
      handler(val) {
        if (val) {
          this.getProductByCatalogId(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    getProductByCatalogId(id) {
      const query = Object.entries({
        PageNumber: 1,
        PageSize: 10,
        CatalogId: id,
      }).map(([k, v]) => `${k}=${v}`).join('&');

      this.$searchService.get(`/products/catalog?${query}`).then((res) => {
        this.items = res.content;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.catalog-product-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 10px;

  &__item {
    min-width: 200px;
  }
}
</style>
