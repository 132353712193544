<template>
  <div class="preview">

  <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      active-class="active-tab"
      hide-slider
  >
    <v-tab
        v-for="item in items"
        :key="item"

    >
      {{ item }}
    </v-tab>
    <v-tabs-items v-model="tab">
      <v-tab-item
          class="preview__tab"
      >
        <MobilePreview :template="template" :activeBlockId="activeBlockId"/>
      </v-tab-item>
      <v-tab-item
      >
        <DesktopPreview :template="template"/>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>

  </div>
</template>

<script>
import MobilePreview from './MobilePreview.vue';
import DesktopPreview from './DesktopPreview.vue';

export default {
  name: 'Preview',
  components: {
    DesktopPreview,
    MobilePreview,
  },
  props: {
    template: {
      type: Array,
      default: () => [],
    },
    activeBlockId: {
      type: Number
    }
  },
  data() {
    return {
      tab: null,
      items: [
        'Mobile',
      ],
    };
  },
};
</script>

<style lang="scss">
.preview {
  display: flex;
  flex-direction: column;
  width: 100%;
  .active-tab {
    background: #C4C4C4;
  }
  &__tab {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #C4C4C4;
    padding-top: 20px;

  }
}
</style>
