<template>
  <v-container fluid class="pa-0">
    <v-card>
      <v-card-title>
        <span>{{current.label}}</span>
        <v-btn absolute right icon @click="$emit('closed')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-container fluid>
          <v-row justify="center">
            <v-col cols="auto">
              <v-file-input id="file" v-model="file" style="display: none"
                            @change="addFile(file)"
                            accept="image/png, image/gif, image/jpeg"
              ></v-file-input>
              <v-btn color="primary" outlined rounded text x-large
                     :disabled="current.quantity === 'solo' ?
                        banners.length > 0 : banners.length > 9"
                     @click="upload">Загрузить картинку</v-btn>
            </v-col>
          </v-row>
          <v-row>
              <v-expansion-panels
                accordion
              >
                <v-expansion-panel
                  v-for="(item,i) in banners"
                  :key="i"
                  class="mt-2"
                >
                  <v-expansion-panel-header>
                    <template #default>
                      <div
                        class="background"
                        :style="`background-image: url(${urlGenerator(item)});
                          height: 410px`"
                      />
                    <v-btn class="edit-banner"
                           text
                           absolute
                           right
                           fab
                           @click.prevent.stop="changePicture(i)">
                      <v-icon color="primary">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-file-input :id="'file' + i" v-model="file" style="display: none"
                                  @change="addFile(file, i)"
                                  accept="image/png, image/gif, image/jpeg"
                    ></v-file-input>

<!--                      <v-img-->
<!--                        :src="urlGenerator(item)"-->
<!--                        contain-->
<!--                        width="100%"-->
<!--                        height="400"-->
<!--                        max-height="400"-->
<!--                        style="border: 1px dashed gray"-->
<!--                      ></v-img>-->
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="d-flex">
                      <v-radio-group
                        v-model="item.pathType"
                        row
                        @change="() => {
                          delete item.path
                          delete item.fullLink
                        }"
                      >
                        <v-radio
                          label="Каталог"
                          value="catalog"
                        ></v-radio>
                        <v-radio
                          label="Продукт"
                          value="product"
                        ></v-radio>
                      </v-radio-group>
                      <v-spacer/>
                      <v-btn
                        v-if="!!item.fullLink"
                        @click="showProductPreview(item)"
                        text outlined color="primary">Предпросмотр</v-btn>
                      <v-btn text outlined color="error"
                             @click="removeFromBanner(i)"
                      >Удалить картинку</v-btn>
                    </div>
                    <v-autocomplete
                      v-if="item.pathType === 'catalog'"
                      rounded
                      outlined
                      placeholder="Поиск"
                      v-model="item.path"
                      item-text="nameRu"
                      item-value="id"
                      :items="item.items"
                      return-object
                      @update:search-input="searchItems($event, item, i)"
                    />
                    <v-text-field
                      v-if="item.pathType === 'product'"
                      rounded
                      outlined
                      placeholder="Введите ссылку"
                      v-model="item.fullLink"
                      :rules="[v => validateUrl(v).valid]"
                    >

                    </v-text-field>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="mb-2">
          <v-spacer/>
          <v-col cols="2">
            <v-btn block color="primary" text @click="$emit('closed')"
                   :loading="btnLoading">Отменить</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn block color="primary" rounded
                   @click="save"
                   :loading="btnLoading"
            >Сохранить</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

import mixin from '@/views/crm/marketplace/components/mixin';

export default {
  name: 'banner',
  components: {
  },
  mixins: [mixin],
  props: {
    current: {
      default: () => {},
    },
    index: {
      default: null,
    },
  },
  data() {
    return {
      file: null,
      pathType: null,
      item: {
        url: null,
        path: null,
        file: null,
        items: [],
      },
      categories: [],
      products: [],
      banners: [],
      hidden: false,
      sss: false,
      str: '',
      items: [],
      timerId: null,
      mainPageId: null,
      lastSearch: '',
      btnLoading: false,
      accessUrls: [
        'test.clevermart.kz',
        'clevermart.kz',
        'test.clevermarket.kz',
        'clevermarket.kz',
        'localhost:8080',
        'localhost:3000',
      ],
      preview: false,
      previewPath: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.mainPageId = this.$route.params.id;
    }
    if (this.current.meta && (Object.keys(this.current.meta)
      || Array.isArray(this.current.meta))) {
      if (Array.isArray(this.current.meta)) {
        this.banners = this.current.meta.map((i) => ({
          ...i,
          items: [i.path],
        }));
      } else {
        const banner = {
          ...this.current.meta,
          items: [],
        };
        banner.items.push(this.current.meta.path);
        this.banners = [].concat(banner);
      }
    }
  },
  methods: {
    changePicture(index) {
      document.getElementById(`file${index}`).click();
    },
    upload() {
      document.getElementById('file').click();
    },
    async addFile(file, index = -1) {
      if (file) {
        const url = await this.readUploadedFileAsUrl(file);
        if (url) {
          if (index >= 0) {
            const item = this.banners[index];
            delete item.imagePath;
            this.$set(this.banners, index, {
              ...item,
              url,
              fileName: file.name,
            });
          } else {
            this.banners.push({
              url,
              fileName: file.name,
              pathType: null,
            });
          }
        }
        this.file = null;
      }
    },
    readUploadedFileAsUrl(inputFile) {
      const temporaryFileReader = new FileReader();

      return new Promise((resolve) => {
        temporaryFileReader.onload = () => {
          resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsDataURL(inputFile);
      });
    },
    async save() {
      if (this.banners.length === 0
          || this.banners.some((i) => Object.values(i).filter((j) => !j).length > 0)) {
        await this.$store.dispatch('tools/setSnackbar', {
          type: 'error',
          message: 'Заполните данные',
        });
      } else {
        const currentBanners = [...this.banners];
        // eslint-disable-next-line no-restricted-syntax
        for await (const banner of currentBanners) {
          if (banner.pathType === 'product') {
            const path = this.validateUrl(banner.fullLink);
            if (!path.valid) {
              await this.$store.dispatch('tools/setSnackbar', {
                type: 'error',
                message: 'Проверьте ссылку на товар',
              });
              return;
            }
          }
        }
        this.btnLoading = true;
        let banners = null;
        // eslint-disable-next-line no-restricted-syntax
        for await (const banner of currentBanners) {
          // eslint-disable-next-line no-prototype-builtins
          if (!banner.hasOwnProperty('imagePath')) {
            banner.imagePath = await this.uploadFile(banner);
            delete banner.url;
            delete banner.items;
          }
          if (banner.pathType === 'product') {
            banner.path = this.validateUrl(banner.fullLink).url;
          }
        }
        if (this.current.quantity === 'multiple') {
          banners = currentBanners.map((i) => {
            const j = { ...i };
            delete j.store;
            return j;
          });
        } else {
          // eslint-disable-next-line prefer-destructuring
          banners = currentBanners[0];
          delete banners.store;
        }
        this.btnLoading = false;
        this.$emit('submitData', banners);
      }
    },
    removeFromBanner(index) {
      this.banners.splice(index, 1);
    },
    searchItems(event, item, index) {
      if (event && event !== this.lastSearch) {
        let url = null;
        if (item.pathType === 'catalog') {
          url = 'catalogs';
        } else if (item.pathType === 'product') {
          url = 'product';
        } else return;

        clearTimeout(this.timerId);

        this.timerId = setTimeout(() => {
          this.$api.get(`/api/v1/crm/${url}/search?name=${event}`).then((res) => {
            this.banners.splice(index, 1, {
              ...item,
              items: res,
            });
          }).finally(() => {
            if (this.banners[index].items.length === 0 && this.banners[index].path) {
              this.banners[index].items = [this.banners[index].path];
            }
          });
        }, 500);
        this.lastSearch = event;
      }
    },
    urlGenerator(item) {
      if (item.url) {
        return item.url;
      }
      if (item.imagePath && item.imagePath.indexOf('data:') > -1) {
        return item.imagePath;
      }
      return '';
    },
    validateUrl(path) {
      let index = -1;
      let marketplaceUrl;
      for (let i = 0; i < this.accessUrls.length; i += 1) {
        if (typeof path === 'string' && path.indexOf(this.accessUrls[i]) > -1) {
          index = path.indexOf(this.accessUrls[i]);
          marketplaceUrl = this.accessUrls[i];
        }
      }
      return {
        valid: index > -1,
        url: index > -1 ? path.substr(index + marketplaceUrl.length) : null,
      };
    },
    showProductPreview(item) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: item.fullLink,
      }).click();
    },
  },
};
</script>

<style scoped lang="scss">
.background {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.edit-banner {
  margin: 0;
  top: 5px;
  right: 5px;
}
</style>
