<template>
  <v-carousel :height="height" cycle :interval="3000">
    <v-carousel-item
      v-for="(item, imgIndex) in currentItems"
      :key="imgIndex"
      :src="item.url"/>
  </v-carousel>
</template>

<script>
import mixin from '@/views/crm/marketplace/components/mixin';

export default {
  name: 'imageCarousel',
  mixins: [mixin],
  props: {
    height: {
      default: '410px',
    },
    items: {
      default: () => [],
    },
  },
  data() {
    return {
      currentItems: [],
    };
  },
  watch: {
    items: {
      handler(val) {
        if (val && val.length > 0) {
          this.getImage(val);
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getImage(val) {
      const items = val;
      // eslint-disable-next-line no-restricted-syntax,no-plusplus
      for (let i = 0; i < items.length; i++) {
        // eslint-disable-next-line no-await-in-loop
        items[i].url = await this.getImageByPath(items[i].imagePath);
      }
      this.currentItems = [...items];
    },
  },
};
</script>

<style scoped>

</style>
