<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    :disabled="disabled"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formattedDate"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        :rules="[v => !!v]"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="date"
      :max="maxDate"
      :min="minDate"
      @input="onInput($event)"
      :locale-first-day-of-year="1"
      :first-day-of-week="1"
    ></v-date-picker>
  </v-menu>
</template>

<script>

import moment from 'moment';

export default {
  name: 'DatePicker',
  props: {
    date: {},
    maxDate: {},
    minDate: {},
    label: {
      type: String,
      default: 'Дата'
    },
    format: {
      type: String,
      default: 'DD-MM-YYYY'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'date',
    event: 'input'
  },
  data() {
    return {
      menu: null,
      currentDate: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    formattedDate() {
      return this.date ? moment(this.date, 'YYYY-MM-DD').format('DD.MM.YYYY') : null
    }
  },
  methods: {
    onInput(val) {
      this.currentDate = val
      this.menu = false
      this.$emit('input', val)
    },
  }

};
</script>

<style scoped>

</style>
