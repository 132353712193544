<template>
  <div
    class="background"
    :style="`background-image: url(${url})`"
  >
    <v-btn v-if="showRemove"
      icon small style="position:absolute; top: 0; right: 0"
      color="black"
       @click.stop.prevent="$emit('removeActionImage')"
      >
        <v-icon small>
          mdi-close
        </v-icon>
    </v-btn>
    <v-btn
      outlined
      color="white"
      class="loadMore"
    >Показать больше</v-btn>
  </div>
</template>

<script>

import mixin from '@/views/crm/marketplace/components/mixin';

export default {
  name: 'actionBlock',
  mixins: [mixin],
  props: {
    path: {
      default: '',
    },
    showRemove: {
      default: () => false,
    },
  },
  data() {
    return {
      url: null,
    };
  },
  watch: {
    path: {
      handler(val) {
        if (val) {
          this.getImage(val);
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getImage(path) {
      if (path && path.indexOf('data:') > -1) {
        this.url = path;
      } else {
        this.url = await this.getImageByPath(path);
      }
    },
  },
};
</script>

<style scoped>

.background {
  width: 230px;
  height: 400px !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.loadMore {
  border-radius: 10px;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;

  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, -15%)
}

</style>
