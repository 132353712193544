<template>
  <v-img v-if="url" height="193" contain :src="generateImageUrl(url)" />
</template>

<script>
import { generateImageUrl } from "@/utils/helper";

export default {
  name: "ImagePreview",
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      url: null
    }
  },
  methods: {
    generateImageUrl
  },
  mounted() {
    if (this.value[0].imageUrl) {
      this.url = this.value[0].imageUrl
    }
  }
};
</script>

<style scoped></style>
