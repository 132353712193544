<template>
  <div class="desktop-preview">
    <div class="desktop-preview__element"
         v-for="item in template"
         :key="item.id"
    >
      <component
          :item="item"
          type="FRONT"
          :is="getComponent(item.type)"
      >

      </component>

    </div>

  </div>
</template>

<script>
import * as components from '@/views/crm/marketplace/components/previewComponents';

export default {
  name: 'DesktopPreview',
  components: {
    ...components,
  },
  props: {
    template: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getComponent(type) {
      switch (type) {
        case 0:
          return 'previewslider';
        case 1:
          return 'previewsixcatalog';
        case 2:
          return 'previewproducts';
        case 5:
          return 'previewproducts';
        default:
          return 'previewslider';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.desktop-preview {
  width: 100%;
  max-width: 100%;
  &__element {
    padding-left: 16px;
    padding-right: 16px;
  }
}

</style>
