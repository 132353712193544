<template>
  <div class="product-block"

  >
    <div
        v-for="(block, index) in current" :key="block.id"
    >
      <v-form ref="form" v-model="block.form">

        <v-text-field
            solo
            placeholder="Введите наименование блока"
            v-model="block.name"
            :disabled="isPreview"
            :rules="[v => !!v || 'Обязательное поле']"
        >

        </v-text-field>
        <v-autocomplete
            solo
            placeholder="Поиск"
            v-model="block.catalogId"
            item-text="nameRu"
            item-value="id"
            :items="block.meta"
            no-filter
            :disabled="isPreview"
            :rules="[v => !!v || 'Обязательное поле']"
            @update:search-input="searchItemsStore($event, index)"
        />
      </v-form>
      <CatalogProductList v-if="block.catalogId" :catalog-id="block.catalogId"/>
      <v-card-actions class="content-actions">
        <v-btn
          rounded
            outlined text
          small
            @click="handleRemoveContent(block)"
          v-if="!isPreview"
        >
          Удалить контент
        </v-btn>
        <v-btn  small rounded @click="handleSaveContent(block, index)" color="primary" v-if="!isPreview">
          Сохранить контент
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>

import mixin from './mixin';
import CatalogProductList from './CatalogProductList.vue';

export default {
  name: 'ProductBlock',
  components: { CatalogProductList },
  mixins: [mixin],
  props: {
    mainPageBlockId: {},
    content: {
      type: Array,
      default: () => [],
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: null,
      current: [],
    };
  },
  watch: {
    content: {
      handler(val) {
        if (val && val.length > 0) {
          this.current.splice(0);
          this.prepareContent(val);
        } else {
          this.$emit('createFirstContent');
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSaveContent(content, index) {
      if (this.$refs.form[index].validate()) {
        const body = {
          ...content,
          contentType: 1,
          mainPageBlockId: this.mainPageBlockId,
        };
        this.$emit('saveContent', body);
      }
    },
    handleRemoveContent(content) {
      const body = {
        ...content,
        contentType: 1,
        mainPageBlockId: this.mainPageBlockId,
      };
      this.$emit('removeContent', body);
    },
    searchItemsStore($event, store) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.$catalogService.get(`/crm-catalogs/search?name=${$event}`).then((res) => {
          const el = this.current[store];
          el.meta = res;
          this.current.splice(store, 1, el);
        });
      }, 500);
    },
    async prepareContent(content) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const i of content) {
        const j = {
          ...i,
        };
        if (i.catalogId) {
          j.meta = await this.getCatalogById(i.catalogId);
        }

        this.current.push(j);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .preview {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
  }
  .content-actions {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: unset;
    gap: 10px;
    margin-top: 20px;
  }
}
</style>
