<template>
  <div
    v-if="htmlText"
    v-html="htmlText"
  />
</template>

<script>
export default {
  name: "TextPreview",
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      htmlText: null,
    }
  },
  created() {
    this.htmlText = this.value?.htmlText
  }
};
</script>

<style scoped></style>
