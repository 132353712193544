<template>
  <v-container fluid style="height: 480px">
    <v-row align="center" dense>
      <v-col cols="auto">
        <label class="block-title">
          {{items.nameRu}}
        </label>
      </v-col>
      <v-divider/>
    </v-row>
    <v-row dense justify="space-around"
           >
      <v-col cols="auto" v-if="items.catalogType === 'action'">
        <action-block :path="this.items.imagePath"/>
      </v-col>
      <v-col cols="auto" v-for="i in computedProducts" :key="i.id">
        <product-card :product="i"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import ActionBlock from '@/views/crm/marketplace/components/actionBlock.vue';

export default {
  name: 'productList',
  props: {
    height: {
      default: '480px',
    },
    items: {
      default: () => {},
    },
  },
  data() {
    return {
      products: [],
    };
  },
  components: {
    ActionBlock,
    'product-card': ProductCard,
  },
  computed: {
    computedProducts() {
      let count;
      if (this.items.catalogType === 'action') {
        count = 4;
      } else {
        count = 5;
      }
      return this.products.slice(0, count);
    },
  },
  watch: {
    items: {
      handler(val) {
        this.fetchByCategory(val.path.id);
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    fetchByCategory(id) {
      this.$api.get(`/api/v1/crm/goods/catalog?catalogId=${id}`).then((res) => {
        this.products = res.content;
      });
    },
  },
};
</script>

<style scoped>
.v-divider {
  height: 6px;
  max-height: 6px;
  background: #005332;
  opacity: 0.2;
  border-radius: 3px;
}

.block-title {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;

  color: #005332;

}

</style>
