<template>
  <div class="catalog-cta">
    <div
        v-for="(item, index) in current"
        :key="item.id"
    >
      <v-text-field
          solo
          placeholder="Введите наименование блока"
          v-model="item.name"
      >

      </v-text-field>
      <v-autocomplete
          solo
          placeholder="Поиск"
          v-model="item.catalogId"
          item-text="nameRu"
          item-value="id"
          :items="item.meta"
          no-filter
          @update:search-input="searchItemsStore($event, index)"
      />
      <v-text-field
          solo
          placeholder="Текст"
          v-model="item.callToActionText"
      >

      </v-text-field>
      <v-text-field
          solo
          placeholder="Призыв к действию"
          v-model="item.callToAction"
      >

      </v-text-field>
      <div class="advertisement">
        <CatalogProductList :catalog-id="catalogId"></CatalogProductList>
      </div>
      <div class="d-flex justify-space-between">
        <v-btn
            outlined text
            @click="handleRemoveContent(item)"
        >
          Удалить контент
        </v-btn>
        <v-btn @click="handleSaveContent(item)" color="primary">
          Сохранить контент
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './mixin';
import CatalogProductList from './CatalogProductList.vue';

export default {
  name: 'CatalogCta',
  mixins: [mixin],
  props: {
    mainPageBlockId: {

    },
    content: {
      type: Array,
      default: () => [],
    },
  },
  components: { CatalogProductList },
  data() {
    return {
      current: [],
    };
  },
  watch: {
    content: {
      handler(val) {
        if (val && val.length > 0) {
          this.current.splice(0);
          this.prepareContent(val);
        } else {
          this.$emit('createFirstContent');
        }
      },
      immediate: true,
    },
  },
  methods: {
    handleSaveContent(content) {
      const body = {
        ...content,
        contentType: 1,
      };
      this.$emit('saveContent', body);
    },
    handleRemoveContent(content) {
      this.$emit('removeContent', content);
    },
    searchItemsStore($event, store) {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.$catalogService.get(`/crm-catalogs/search?name=${$event}`).then((res) => {
          const el = this.current[store];
          el.meta = res;
          this.current.splice(store, 1, el);
        });
      }, 500);
    },
    async prepareContent(content) {
      // eslint-disable-next-line no-restricted-syntax
      for await (const i of content) {
        const j = {
          ...i,
        };
        if (i.catalogId) {
          j.meta = await this.getCatalogById(i.catalogId);
        }

        this.current.push(j);
      }
    },
  },
};
</script>

<style lang="scss">
.catalog-cta {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .advertisement {
    flex-direction: row;
    flex-wrap: wrap;
    &-content {
      max-width: 31%;
      &__type-actions {
        flex-wrap: wrap;
        flex-direction: column;
        padding: unset;
        gap: 10px;
      }
    }
  }
}
</style>
