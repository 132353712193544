<template>
  <CardWrapper title="Картинка" @clear="onClear" :disabled="disabled">
    <ImageBlockContent
      v-model="currentValue[ImageCardDto.imageUrl]"
      @input="onInput"
      :disabled="disabled"
    ></ImageBlockContent>
  </CardWrapper>
</template>

<script>
import ImageBlockContent from "@/views/crm/marketplace/components/ImageBlockContent.vue";
import CardWrapper from "@/views/crm/marketplace/components/constructorComponents/CardWrapper.vue";
import { ImageCardDto } from "@/views/crm/marketplace/components/constructorComponents/constuctorDtos";

export default {
  name: "ImageCard",
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    CardWrapper,
    ImageBlockContent
  },
  model: {
    prop: "value",
    event: "input"
  },
  watch: {
    value: {
      handler(val) {
        this.currentValue = val;
      },
      immediate: true
    }
  },
  data() {
    return {
      ImageCardDto,
      currentValue: null,
    };
  },
  methods: {
    onInput(value) {
      this.$emit("input", {
        [ImageCardDto.imageUrl]: value
      });
    },
    onClear() {
      this.$emit("clear");
    },
  }
};
</script>

<style scoped></style>
