<template>
  <v-dialog :value="show" max-width="375" class="preview-modal">
    <v-card max-width="375" class="preview-modal__card">
      <v-card-title class="preview-modal__title">
        <span class="preview-modal__title-text">Превью</span>
        <v-spacer/>
        <v-btn
          @click="handleDialogClose"
          text
          rounded
          dark
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="preview-modal__body">
        <component
          v-for="item in informationalBlock.items"
          :key="item.id"
          :value="item[Keys[item.contentType]]"
          :block="informationalBlock"
          :is="getComponent(item)"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { generateImageUrl } from '@/utils/helper';
import * as components from './constructorComponents/index'
import {
  blockKeys,
  Keys
} from '@/views/crm/marketplace/components/constructorComponents/constuctorDtos';

export default {
  name: 'PreviewModal',
  components: {
    ...components
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    informationalBlock: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      Keys,
    }
  },
  methods: {
    generateImageUrl,
    handleDialogClose() {
      this.$emit('update:show', false)
    },
    getComponent({ contentType }) {
      switch (contentType) {
        case blockKeys.blockText: return 'textPreview'
        case blockKeys.blockQuestion: return 'questionPreview'
        case blockKeys.blockImage: return 'imagePreview'
        case blockKeys.blockButton: return 'buttonPreview'
        case blockKeys.blockTimer: return 'timerPreview'
      }
    }
  }
};
</script>

<style scoped lang="scss">
.preview-modal {
  border-radius: 12px;
  overflow: hidden;
  &__title {
    padding: 16px 0 16px 24px !important;
    background: #286852;
  }
  &__title-text{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
  &__body {
    display: flex;
    flex-direction: column;
    padding: 16px !important;
    gap: 24px;
    overflow-y: scroll;
  }
  &__body-image {
    border-radius: 16px;
  }
}
</style>
