<template>
  <v-card
      class="advertisement-content"
  >
    <v-card-text>
      <v-form v-model="valid" ref="form">
        <div class="advertisement-content__images">
          <div class="advertisement-content__image">
            <div v-if="!imageFrontUrl" class="advertisement-content__placeholder" >
              <v-btn  @click="upload('front')" width="100%" height="100%" text :disabled="disabled">
                <v-icon   x-large color="#FFFFFFF">
                  mdi-image-plus
                </v-icon>
              </v-btn>
            </div>
            <v-img v-else height="130" contain
                   :src="generateImageUrl(imageFrontUrl)">
              <v-btn absolute x-small fab text style="right: 0;"
                     :disabled="disabled"
                     @click="removeImage('front')"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-img>
          </div>
          <div class="advertisement-content__image">
            <div v-if="!imageMobileUrl" class="advertisement-content__placeholder" >
              <v-btn  @click="upload('mobile')" width="100%" height="100%" text :disabled="disabled">
                <v-icon   x-large color="#FFFFFFF">
                  mdi-image-plus
                </v-icon>
              </v-btn>
            </div>
            <v-img v-else height="130" contain :src="generateImageUrl(imageMobileUrl)">
              <v-btn absolute x-small fab text style="right: 0;"
                     :disabled="disabled"
                     @click="removeImage('mobile')"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-img>
          </div>
        </div>
        <div class="advertisement-content__type">
          <v-text-field
            v-if="showName"
            solo
            single-line
            placeholder="Наименование"
            v-model="name"
            :disabled="disabled"
            :rules="[v => !!v || 'Обязательное поле']"
            >
          </v-text-field>
          <v-expansion-panels accordion v-model="contentType">
            <v-expansion-panel v-if="showProductUrl">
              <v-expansion-panel-header>Ссылка на товар</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                    solo
                    :disabled="disabled"
                    :rules="[v => (!!v || !!this.catalogId) || 'Укажите ссылку на товар']"
                    v-model="productUrl">
                </v-text-field>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="showCatalogId">
              <v-expansion-panel-header>Каталог</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-autocomplete
                    solo
                    placeholder="Поиск"
                    v-model="catalogId"
                    item-text="nameRu"
                    item-value="id"
                    :items="meta"
                    :disabled="disabled"
                    :rules="[v => (!!v || !!this.productUrl) || 'Выберите каталог']"
                    no-filter
                    @update:search-input="searchItems"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-form>

    </v-card-text>
    <v-card-actions class="justify-space-between flex-nowrap">
      <v-btn
        rounded
          outlined text
          small
          @click="handleRemoveContent"
          v-if="!disabled"
      >
        Удалить контент
      </v-btn>
      <v-btn rounded small @click="handleSaveContent" color="primary"
             style="margin-left: unset; margin-top: 5px;" v-if="!disabled">
        Сохранить контент
      </v-btn>
    </v-card-actions>

    <CmImageUploader
        style="display: none;"
        no-crop
        :hideButton="true" :id="`file-input-${content.id}`" @set="handleImage"/>
  </v-card>
</template>

<script>
import mixin from './mixin';

export default {
  name: 'AdvertisementBanner',
  mixins: [mixin],
  props: {
    mainPageBlockId: {
      type: Number,
    },
    content: {
      type: Object,
      default: () => {},
    },
    showName: {
      type: Boolean,
      default: false,
    },
    showProductUrl: {
      type: Boolean,
      default: false,
    },
    showCatalogId: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    disabled: {
     type: Boolean,
     default: false
    }
  },
  data() {
    return {
      meta: [],
      contentType: 0,
      catalogId: null,
      productUrl: null,
      id: null,
      name: null,
      imageFrontUrl: null,
      imageMobileUrl: null,
      lastUpload: null,
      frontFile: null,
      mobileFile: null,
      valid: false,
    };
  },
  watch: {
    content: {
      handler() {
        this.preparations();
      },
      immediate: true,
    },
    expanded: {
      handler(val) {
        if (val) {
          this.contentType = 1;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async preparations() {
      if (this.content) {
        if (this.content.catalogId) {
          this.meta = await this.getCatalogById(this.content.catalogId);
          this.catalogId = this.content.catalogId;
        }
        if (this.expanded && !this.content.contentType) {
          this.contentType = 0;
        } else {
          this.contentType = this.content.contentType;
        }
        if (this.content.images && this.content.images.length > 0) {
          this.content.images.forEach((i) => {
            if (i.type === 'FRONT') {
              this.imageFrontUrl = i.imageUrl;
            } else if (i.type === 'MOBILE') {
              this.imageMobileUrl = i.imageUrl;
            }
          });
        }
        this.id = this.content.id;
        this.name = this.content.name;
        this.productUrl = this.content.productUrl;
      }
    },
    upload(type) {
      this.lastUpload = type;
      document.querySelector(`#file-input-${this.content.id}`).click();
    },
    handleImage({ file, url }) {
      if (this.lastUpload === 'front') {
        this.imageFrontUrl = url;
        this.frontFile = file;
      } else if (this.lastUpload === 'mobile') {
        this.imageMobileUrl = url;
        this.mobileFile = file;
      }
    },
    handleSaveContent() {
      if (!this.$refs.form.validate()) {
        return this.$toast.warning('Заполните поля');
      }
      if (!this.imageMobileUrl) {
        return this.$toast.warning('Загрузите картинку для мобильных устройств');
      }

      if (!this.imageFrontUrl) {
        return this.$toast.warning('Загрузите картинку для WEB');
      }

      this.$emit('saveContent', {
        contentType: this.contentType,
        productUrl: this.productUrl,
        name: this.name,
        mainPageBlockId: this.mainPageBlockId,
        catalogId: this.catalogId,
        id: this.id,
        url: this.url,
        frontFile: this.frontFile,
        mobileFile: this.mobileFile,
      });
      return true;
    },

    handleRemoveContent() {
      this.$emit('removeContent', {
        id: this.id,
      });
    },
    removeImage(type) {
      if (type === 'front') {
        this.imageFrontUrl = null;
        this.frontFile = null;
      } else if (type === 'mobile') {
        this.imageMobileUrl = null;
        this.mobileFile = null;
      }
      this.lastUpload = null;
    },
  },
};
</script>

<style lang="scss">
.v-expansion-panel-content__wrap {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.advertisement-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  &__images {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
  }
  &__image {
    width: 50%;
    border: 1px solid #FFFFFF;
  }
  &__placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 130px;
    background: linear-gradient(0deg, #C4C4C4, #C4C4C4);
    i {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      height: 100%;
    }
  }
}
.v-card__actions {
  flex-wrap: wrap;
}

</style>
