<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        Настройки каталога
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="4">
              <v-form ref="form" v-model="valid">
                <v-text-field
                  placeholder=""
                  persistent-placeholder
                  :rules="[v => !!v]"
                  label="Наименование блока на русском"
                  v-model="item.nameRu"
                />
                <v-text-field
                  placeholder=""
                  persistent-placeholder
                  :rules="[v => !!v]"
                  label="Наименование блока на казахском"
                  v-model="item.nameKk"
                />
                <v-autocomplete
                  placeholder=""
                  persistent-placeholder
                  :rules="[v => !!v]"
                  label="Каталог"
                  item-text="nameRu"
                  item-value="id"
                  v-model="item.path"
                  :search-input.sync="str"
                  :items="items"
                  @update:search-input="searchItems(item)"
                  @change="fetchByCatalog(item.path.id)"
                  return-object
                />
                <v-select
                  placeholder=""
                  persistent-placeholder
                  color="primary"
                  label="Тип"
                  v-model="item.catalogType"
                  :items="catalogTypes"
                  item-text="name"
                  item-value="type"/>
                <div
                  v-if="item.catalogType === 'action'"
                  class="d-flex"
                >
                  <v-file-input v-model="file" id="file" style="display: none"
                                @change="addFile(file)"/>
                  <v-btn color="primary" outlined rounded text x-large
                         v-if="!item.imagePath && !item.image"
                         @click="uploadClick">Загрузить картинку</v-btn>

                </div>
              </v-form>
            </v-col>
            <v-col cols="8">
              <v-row style="height: 440px; overflow: scroll">
                <v-col cols="3" v-if="item.imagePath || item.image ">
                  <action-block :path="item.imagePath || item.image.url"
                                :show-remove="true"
                                @removeActionImage="removeActionCard"
                  />
                </v-col>
                <v-col cols="3" v-for="i in products" :key="i.id">
                  <v-fade-transition>
                    <product-card :product="i" />
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-row class="mb-2">
          <v-spacer/>
          <v-col cols="2">
            <v-btn block color="primary" text @click="$emit('closed')">Отменить</v-btn>
          </v-col>
          <v-col cols="2">
            <v-btn block color="primary" rounded
                   @click="save"
            >Сохранить</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import ProductCard from '@/components/ProductCard.vue';
import mixin from '@/views/crm/marketplace/components/mixin';
import ActionBlock from '@/views/crm/marketplace/components/actionBlock.vue';
import getEnv from '@/utils/env';

export default {
  name: 'catalog',
  components: {
    ActionBlock,
    'product-card': ProductCard,
  },
  props: {
    current: {
      default: () => {},
    },
    index: {
      default: null,
    },
  },
  mixins: [mixin],
  data() {
    return {
      item: {},
      valid: false,
      catalogTypes: [
        {
          name: 'С призывом к действию',
          type: 'action',
        },
        {
          name: 'Прокручивающийся',
          type: 'scroll',
        },
      ],
      file: null,
      mainPageId: null,
      timerId: null,
      items: [],
      str: '',
      products: [],
    };
  },
  created() {
    if (this.$route.params.id) {
      this.mainPageId = this.$route.params.id;
    }
    if (this.current.meta && Object.keys(this.current.meta)) {
      this.item = { ...this.current.meta };
      this.items = [this.current.meta.path];
      this.searchItems();
      this.fetchByCatalog(this.current.meta.path);
    }
  },
  methods: {
    uploadClick() {
      document.getElementById('file').click();
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.item.catalogType === 'action' && this.item.image) {
          this.item.imagePath = await this.uploadFile(this.item.image);
        }
        delete this.item.image;
        delete this.item.items;
        this.$emit('submitData', this.item);
      } else {
        await this.$store.dispatch('tools/setSnackbar', {
          type: 'error',
          message: 'Заполните данные',
        });
        setTimeout(() => {
          this.$refs.form.resetValidation();
        }, 5000);
      }
    },
    searchItems() {
      if (!this.str) this.str = '';
      clearTimeout(this.timerId);

      this.timerId = setTimeout(() => {
        this.$api.get(`/api/v1/crm/catalogs/search?name=${this.str}`)
          .then((res) => {
            this.items = [...res];
          });
      }, 500);
    },
    fetchByCatalog(id) {
      let currentId = id;
      if (typeof id === 'object') {
        currentId = id.id;
      }
      this.$api.get(`/api/v1/crm/goods/catalog?catalogId=${currentId}`).then((res) => {
        this.products = res.content;
      });
    },
    async addFile(file) {
      if (file) {
        const url = await this.readUploadedFileAsUrl(file);
        if (url) {
          this.item.image = {
            url,
            fileName: file.name,
          };
        }
        this.file = null;
      }
    },
    readUploadedFileAsUrl(inputFile) {
      const temporaryFileReader = new FileReader();

      return new Promise((resolve) => {
        temporaryFileReader.onload = () => {
          resolve(temporaryFileReader.result);
        };
        temporaryFileReader.readAsDataURL(inputFile);
      });
    },
    styleGenerator() {
      const { item } = this;
      if (item.imagePath) {
        return `background-image: url(${getEnv('VUE_APP_URL_MINIO')}/ecom/${item.imagePath});`;
      }

      if (item.image && item.image.url) {
        return `background-image: url(${item.image.url})`;
      }
      return 'background-color: gray';
    },
    removeActionCard() {
      const { item } = this;
      delete item.imagePath;
      delete item.image;
      this.item = { ...item };
    },
    showCard() {
      let bool = null;
      if (this.item.imagePath) bool = true;
      bool = !!this.item.image;
      return bool;
    },
  },
};
</script>

<style scoped>

</style>
